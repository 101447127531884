import React from 'react';
import QrReader from 'react-qr-reader';
import {
    Page,
    Toolbar,
    Link,
    Tabs,
    Tab,
    Card,
    CardContent,
    Icon,
    Preloader,
    List,
    ListItem,
    Block,
    Searchbar,
    Popup,
    Navbar,
    Fab,
    Button,
    BlockTitle,
    AccordionContent,
    NavRight
} from 'framework7-react';
import {API_URL} from '../../../api';
import PageBlock from '../../shared/pageBlock/pageBlock';

export default class extends React.Component {

  state = {
    result: false,
    loading: false,
    c_scan: '',
    loading_participants: true,
    participants: null,
    info_popover: false,
    show_scanner: false,
    participants_popover: false
  }

  handleScan = data => {
    if (!this.state.loading && data && this.state.c_scan !== data) {
      this.xhrCheckTicket(data);
    }
  }
  
  handleError = err => {
    this.setState({
      loading:false,
      result: false,
      valid:false,
      error_mgs: 'En ukjent feil har oppstått, prøv igjen.',
      c_scan: ''
    });
  }

xhrCheckTicket(tid){

    const eid = this.$f7route.params.id;

    this.setState({
        loading:true,
        result: false,
        c_scan: tid,
        user_result: false,
        data: false,
        participants_popover: false
    });

    fetch(API_URL+'/ticket/check', {
        method: 'POST',
        body: JSON.stringify({
          eid: eid,
          tid: tid
        })
    })
    .then(response => {
    return response.json()
    })
    .then(data => {
        if(data.err_code === 0){
            this.setState({
                loading: false,
                result: true,
                valid: data.valid,
                data: data
            });
        }else{
          this.setState({
              loading:false,
              result: true,
              valid:false,
              error_mgs: data.err_mgs,
              data: data
          });
        }
    })
    .catch(err => {
      this.setState({
          loading:false,
          result: false,
          valid:false,
          error_mgs: 'En ukjent feil har oppstått, prøv igjen senere.',
          c_scan: ''
      });
    })

}


  componentDidMount(){
    let hide_info_popup = localStorage.getItem("hide-info-popup");
    if(!hide_info_popup){
      this.setState({info_popover:true});
    }else{
      this.setState({show_scanner:true})
    }
  }

  render() {
    return (
    <Page pageContent={false}>
      <Toolbar tabbar top>
        <Link tabLink="#tab-1" tabLinkActive>Scan</Link>
        <Link tabLink="#tab-2">Søk</Link>
      </Toolbar>
      <Tabs animated>
        <Tab id="tab-1" className="page-content" tabActive>
          <PageBlock width={'420px'}>
            {this.state.show_scanner &&
            <QrReader
              style={{ width: '100%' }}
              onError={this.handleError}
              onScan={this.handleScan}
              showViewFinder={true}
            />
            }
            {this.renderScanCard()}
            <br/>
            <br/>
            <br/>
            <br/>
          </PageBlock>
        </Tab>
        <Tab id="tab-2" className="page-content">
          <PageBlock width={'620px'}>
            <Searchbar
              disableButtonText="Avbryt"
              placeholder="Søk på ID, telefon, e-post eller navn."
              clearButton={true}
              customSearch
              onSearchbarSearch={(e)=>this.xhrSearchParticipant(e.query)}
              autocomplete={false}
            ></Searchbar>
            {this.state.search_results && this.render_search_results()}
          </PageBlock>
        </Tab>
      </Tabs>
      <Fab position="right-bottom" slot="fixed" onClick={()=>this.setState({info_popover:true})}>
        <Icon material="info"></Icon>
      </Fab>
      <Popup opened={this.state.info_popover} onPopupClosed={() => this.setState({info_popover:false,show_scanner:true})}>
          <Page>
            <Navbar title="Registrer deltaker"/>
            <Block style={{marginTop:'0',marginBottom:'34px'}}>
              <Icon className="functions-page-icon" size={96} f7='qrcode_viewfinder'/>
            </Block>
            <BlockTitle>Kontroller deltakerne.</BlockTitle>
            <Block>
              <p>På denne siden kan du registrere deltakere når de ankommer arrangementet, og til å verifisere at deltakeren er påmeldt.</p>
            </Block>
            <BlockTitle>Skann QR-koden.</BlockTitle>
            <Block>
              <p>Du verifiserer veltakeren med å skanne QR-koden deltakeren har fått etter påmeldingen. Du kan også søke opp deltakeren, eller felter i registreringen.</p>
              <p>Deltakeren blir automatisk registrert som deltatt når du skanner QR-koden, og du kan se informasjon om påmeldingen til deltakeren.</p>
            </Block>
            <Block>
              <Button popupClose fill style={{margin:'auto',maxWidth:'200px',marginTop:'44px'}}>
                Lukk
              </Button>
              <Link onClick={()=>localStorage.setItem('hide-info-popup', 1)} popupClose style={{marginTop:'20px',width:'100%',textAlign:'center'}}>Ikke vis igjen</Link>
            </Block>
          </Page>
        </Popup>
        <Popup opened={this.state.participants_popover} onPopupClosed={() => this.setState({participants_popover:false})} push>
          <Page>
            <Navbar title="Registrer deltaker">
              <NavRight>
                <Link popupClose>Lukk</Link>
              </NavRight>
            </Navbar>
            {this.state.user_result &&
              <>
              {this.state.user_result.valid
                ? (
                  <>
                  {this.state.user_result.waiting ?
                  <Card outline>
                    <CardContent style={{color:'#fff', backgroundColor:'#e74c3c'}}>
                      <Icon material="error" style={{marginRight:'12px',marginTop:'-5px'}}/>
                      <span style={{fontSize:'18px'}}>Venteliste</span>
                    </CardContent>
                  <CardContent>
                    <p>Deltakeren står på venteliste til dette arrangementet.</p>
                    <p>
                      Deltaker er på <b>{this.state.user_result.que}</b> plass i køen.
                    </p>
                  </CardContent>
                  </Card>
                  :
                  <Card outline style={{color:'#fff', backgroundColor:'#6ab04c'}}>
                    <CardContent>
                      <Icon material="error" style={{marginRight:'12px',marginTop:'-5px'}}/>
                      <span style={{fontSize:'18px'}}>Gyldig</span>
                    </CardContent>
                  </Card>
                  }
                  {this.state.user_result.checked_in === 1 &&
                  <Card outline>
                    <CardContent style={{color:'#fff', backgroundColor:'#e74c3c'}}>
                      <Icon material="error" style={{marginRight:'12px',marginTop:'-5px'}}/>
                      <span style={{fontSize:'18px'}}>Deltaker er registrert tidligere.</span>
                    </CardContent>
                    <CardContent>
                      <p>
                      Deltakeren ble registrert for {this.getTimeLeft(this.state.user_result.checked_in_time)} siden. ({this.timeConverter(this.state.user_result.checked_in_time)})
                      </p>
                    </CardContent>
                  </Card>
                  }

                <Card outline>
                  {this.state.user_result.multiple_event_col &&
                  <>
                    <CardContent>
                      <p style={{fontSize:"18px",lineHeight:"16px"}}>{this.state.user_result.event_col.title}</p>
                      <div style={{marginTop:'-6px'}} dangerouslySetInnerHTML={{ __html: this.state.user_result.event_col.desc }}></div>
                    </CardContent>
                    <CardContent style={{height:'5px',padding:0,backgroundColor:'var(--f7-theme-color)'}}></CardContent>
                  </>
                  }
                  {this.render_participant_user_result()}
                </Card>
                </>
                ) : (
                  <>
                  <Card outline style={{color:'#fff', backgroundColor:'#e74c3c'}}>
                    <CardContent>
                      <Icon material="error" style={{marginRight:'12px',marginTop:'-5px'}}/>
                      <span style={{fontSize:'18px'}}>Ikke gyldig</span>
                    </CardContent>
                    {this.state.error_mgs &&
                    <CardContent>
                      <p>{this.state.error_mgs}</p>
                    </CardContent>
                    }
                  </Card>
                  {this.state.user_result.event_link &&
                  <Card outline>
                    <CardContent>
                      <p>
                      Denne billetten er gyldig for <b>{this.state.user_result.err_event_type}</b>.
                      </p>
                      <i>Denne innslippslenken gjelder for <b>{this.state.user_result.event_link.title}</b>. Dersom dette er feil arrangement, må du få en administrtor til å gå til kontrollpanelet for dette arrangementet (admin.pameldinger.no) og bekrefte at dette er riktig innslippslenke.</i>
                    </CardContent>
                  </Card>
                  }
                  </>
                )
              }
              </>
            }
          </Page>
        </Popup>
    </Page>
    );
  }

  xhrSearchParticipant(q){

    const app = this.$f7;
    const eid = this.$f7route.params.id;

    if(q.length > 2){
      this.setState({load_search:true});
      fetch(API_URL+'/ticket/search', {
          method: 'POST',
          body: JSON.stringify({
            eid: eid,
            q: q
          })
      })
      .then(response => {
      return response.json()
      })
      .then(data => {
        this.setState({load_search:false,search_results:data.data});
      })
      .catch(err => {
        app.dialog.close();
        app.dialog.alert(`Får ikke kontakt med databasen.`,'Nettverksfeil');
      })

    }else{
      this.setState({load_search:false,search_results:false});
    }

  }

  render_search_results(){
    return <Card outline>
        <List mediaList>
          {this.state.search_results.map( (n) => (
          <ListItem link="#" title={n.name?n.name:n.email} subtitle={<><Icon material="phone" colorTheme size={12}></Icon> <span style={{color:'#666'}}>(+{n.tel_prefix})</span> {n.tel}<br/><Icon material="mail" colorTheme size={12}></Icon> {n.email}</>} after={n.t_id.match(/.{1,2}/g).join("-").toUpperCase()} onClick={()=>this.get_ticket_user_xhr(n.id)}>
            {n.checked_in === 1 ?
            <Icon slot="media" material="check_box" colorTheme></Icon>
            :
            <Icon slot="media" material="check_box_outline_blank" colorTheme></Icon>
            }
          </ListItem>
          ))}
        </List>
      </Card>
  }

  get_ticket_user_xhr(id){
    const eid = this.$f7route.params.id;
    const app = this.$f7;

    this.setState({
        loading:true,
        user_result: false,
    });
    app.dialog.preloader('Vent litt');
    fetch(API_URL+'/ticket/check/user', {
        method: 'POST',
        body: JSON.stringify({
          eid: eid,
          id: id
        })
    })
    .then(response => {
    return response.json()
    })
    .then(data => {
        app.dialog.close();
        if(data.err_code === 0){
            this.setState({
                loading: false,
                user_result: data,
                data: data,
                participants_popover: true
            });
        }else{
          this.setState({
              loading:false,
              user_result: false,
              error_mgs: data.err_mgs,
              participants_popover: false
          });
        }
    })
    .catch(err => {
      app.dialog.close();
      this.setState({
          loading:false,
          user_result: false,
          error_mgs: 'En ukjent feil har oppstått, prøv igjen senere.',
          participants_popover: false
      });
    })
  }


  renderDataCol(c) {
    
    if(this.state.data.event_col.fields.find(x => x.id === c.c_id)){
    let title = this.state.data.event_col.fields.find(x => x.id === c.c_id).title;
    let c_data = this.state.data.event_col.fields.find(x => x.id === c.c_id).data;
    let data = c.data;

    if(c.type === 3){
        let arr = c_data;
        if(Array.isArray(arr)){
          data = arr[data];
        }
    }else if(c.type === 4){

        let arr = c_data;
        if(Array.isArray(arr)){
        
            let res = '';
            
            JSON.parse(data).forEach((e,i) => {
                if(e === 1){
                    res = res + `${arr[i]}, `;
                }
            });
            data = res.substring(0, res.length-2);;
        
        }
    }

    return <>
    <BlockTitle>{title}</BlockTitle>
    <Block style={{borderBottom:'1px solid rgba(0, 0, 0, .2)'}}>
        <p>
           {data}
        </p>
    </Block>
    </>;
    }
    return false;
}

  render_participant_data = () => {
    return <CardContent>
      <List accordionList>
        <ListItem accordionItem title="Deltakerinformasjon">
          <AccordionContent>
              <BlockTitle style={{marginTop:'16px'}}>Navn</BlockTitle>
              <Block style={{borderBottom:'1px solid rgba(0, 0, 0, .2)'}}>
                  <p>
                     {this.state.data.signup_data.name}
                  </p>
              </Block>
              <BlockTitle style={{marginTop:'16px'}}>E-post</BlockTitle>
              <Block style={{borderBottom:'1px solid rgba(0, 0, 0, .2)'}}>
                  <p>
                     {this.state.data.signup_data.email}
                  </p>
              </Block>
              <BlockTitle style={{marginTop:'16px'}}>Telefon</BlockTitle>
              <Block style={{borderBottom:'1px solid rgba(0, 0, 0, .2)'}}>
                  <p>
                  <span style={{color:'#666'}}>(+{this.state.data.signup_data.tel_prefix})</span> {this.state.data.signup_data.tel}
                  </p>
              </Block>
              {this.state.data.signup_data.data[0].data !== null &&
                this.state.data.signup_data.data.map( (n) => (
                  this.renderDataCol(n)
                ))
              }
          </AccordionContent>
        </ListItem>
      </List>
    </CardContent>
  }

  render_participant_user_result = () => {
    return <CardContent>
      <List accordionList>
        <ListItem accordionItem title="Deltakerinformasjon">
          <AccordionContent>
              <BlockTitle style={{marginTop:'16px'}}>Navn</BlockTitle>
              <Block style={{borderBottom:'1px solid rgba(0, 0, 0, .2)'}}>
                  <p>
                     {this.state.user_result.signup_data.name}
                  </p>
              </Block>
              <BlockTitle style={{marginTop:'16px'}}>E-post</BlockTitle>
              <Block style={{borderBottom:'1px solid rgba(0, 0, 0, .2)'}}>
                  <p>
                     {this.state.user_result.signup_data.email}
                  </p>
              </Block>
              <BlockTitle style={{marginTop:'16px'}}>Telefon</BlockTitle>
              <Block style={{borderBottom:'1px solid rgba(0, 0, 0, .2)'}}>
                  <p>
                  <span style={{color:'#666'}}>(+{this.state.user_result.signup_data.tel_prefix})</span> {this.state.user_result.signup_data.tel}
                  </p>
              </Block>
              {this.state.user_result.signup_data.data[0].data !== null &&
                this.state.user_result.signup_data.data.map( (n) => (
                  this.renderDataCol(n)
                ))
              }
          </AccordionContent>
        </ListItem>
      </List>
    </CardContent>
  }

  renderScanCard(){
    return (
      <>
      {this.state.loading
        ? (
            <div className="text-align-center" style={{margin:'26px'}}>
              <Preloader></Preloader>
            </div>
        ) : (
          this.state.result &&
          <>
          {this.state.valid
            ? (
              <>
              {this.state.data.waiting ?
              <Card outline>
                <CardContent style={{color:'#fff', backgroundColor:'#e74c3c'}}>
                  <Icon material="error" style={{marginRight:'12px',marginTop:'-5px'}}/>
                  <span style={{fontSize:'18px'}}>Venteliste</span>
                </CardContent>
              <CardContent>
                <p>Deltakeren står på venteliste til dette arrangementet.</p>
                <p>
                  Deltaker er på <b>{this.state.data.que}</b> plass i køen.
                </p>
              </CardContent>
              </Card>
              :
              <Card outline style={{color:'#fff', backgroundColor:'#6ab04c'}}>
                <CardContent>
                  <Icon material="error" style={{marginRight:'12px',marginTop:'-5px'}}/>
                  <span style={{fontSize:'18px'}}>Gyldig</span>
                </CardContent>
              </Card>
              }
              {this.state.data.checked_in === 1 &&
              <Card outline>
                <CardContent style={{color:'#fff', backgroundColor:'#e74c3c'}}>
                  <Icon material="error" style={{marginRight:'12px',marginTop:'-5px'}}/>
                  <span style={{fontSize:'18px'}}>Deltaker er registrert tidligere.</span>
                </CardContent>
                <CardContent>
                  <p>
                  Deltakeren ble registrert for {this.getTimeLeft(this.state.data.checked_in_time)} siden. ({this.timeConverter(this.state.data.checked_in_time)})
                  </p>
                </CardContent>
              </Card>
              }

            <Card outline>
              {this.state.data.multiple_event_col &&
              <>
                <CardContent>
                  <p style={{fontSize:"18px",lineHeight:"16px"}}>{this.state.data.event_col.title}</p>
                  <div style={{marginTop:'-6px'}} dangerouslySetInnerHTML={{ __html: this.state.data.event_col.desc }}></div>
                </CardContent>
                <CardContent style={{height:'5px',padding:0,backgroundColor:'var(--f7-theme-color)'}}></CardContent>
              </>
              }
              {this.render_participant_data()}
            </Card>
            </>
            ) : (
              <>
              <Card outline style={{color:'#fff', backgroundColor:'#e74c3c'}}>
                <CardContent>
                  <Icon material="error" style={{marginRight:'12px',marginTop:'-5px'}}/>
                  <span style={{fontSize:'18px'}}>Ikke gyldig</span>
                </CardContent>
                {this.state.error_mgs &&
                <CardContent>
                  <p>{this.state.error_mgs}</p>
                </CardContent>
                }
              </Card>
              {this.state.data.event_link &&
              <Card outline>
                <CardContent>
                  <p>
                  Denne billetten er gyldig for <b>{this.state.data.err_event_type}</b>.
                  </p>
                  <i>Denne innslippslenken gjelder for <b>{this.state.data.event_link.title}</b>. Dersom dette er feil arrangement, må du få en administrtor til å gå til kontrollpanelet for dette arrangementet (admin.pameldinger.no) og bekrefte at dette er riktig innslippslenke.</i>
                </CardContent>
              </Card>
              }
              </>
            )
          }
          </>
        )
      }
      </>
    )
  }

  getTimeLeft(UNIX_timestamp){
    
    UNIX_timestamp = parseInt(UNIX_timestamp);

    var timeNow = Math.floor((new Date().getTime())/1000);
    var d = timeNow - UNIX_timestamp;
    var d_days = Math.floor(d / (24*60*60));
    var d_hours = Math.floor(d / (60*60));
    var d_minutes = Math.floor(d / 60);
    var d_second = Math.floor(d);
    if (d_days >= 2) {
        return d_days + ' dager';
    }
    else if (d_days < 2 && d_days >= 1) {
        return d_days + ' dag';
    }
    else if (d_days < 1 && d_hours >= 2) {
        return d_hours + ' timer ' + (d_minutes-(60*d_hours)) + ' minutter';
    }
    else if (d_hours < 2 && d_hours >= 1) {
        return d_hours + ' time';
    }
    else if (d_hours < 1 && d_minutes >= 2) {
        return d_minutes + ' minutter';
    }
    else if (d_minutes < 2 && d_minutes >= 1) {
        return d_minutes + ' minutt';
    }
    else if (d_minutes < 1 && d_second >= 0) {
        return ' mindre enn 1 minutt';
    }
  }

  timeConverter(UNIX_timestamp){
    var a = new Date(UNIX_timestamp * 1000);
    var year = "0" + a.getFullYear();
    var month = "0" + (a.getMonth()+1);
    var date = "0" + a.getDate();
    var hour = "0" + a.getHours();
    var min = "0" + a.getMinutes();
    var time = date.substr(-2) + '.' + month.substr(-2) + '.' + year.substr(-2) + ', kl. ' + hour.substr(-2) + '.' + min.substr(-2) ;
    return time;
  }

};
