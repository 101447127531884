import React from 'react';
import {
    Page,
    Block,
} from 'framework7-react';

export default class extends React.Component {

  render() {
    return (
  <Page>
    <div style={{width:'100%',maxWidth:'400px',marginLeft:'auto',marginRight:'auto'}}>
      <br/>
      <h2 large className="text-align-center">Oisann! Du må opprette en innslippslenke.</h2>
      <Block className="text-align-center">
        <p>En administrator må opprette en innslippslenke. Gå til <a className="external" href="https://pameldinger.no/hjelp">https://pameldinger.no/hjelp</a> for å se hvordan du gjør det.</p>
      </Block>
      <br/>
    </div>
  </Page>);
  }
};