import * as React from 'react';
import './pageBlock.css'

export default class PageBlock extends React.Component {

    constructColor() {
        if(this.props.color){
            return {backgroundColor:this.props.color}
        }
        return null;
    }

    constructWidth() {
        if(this.props.width){
            return {marginTop:0,maxWidth:this.props.width}
        }
        return {marginTop:0};
    }

    render() {
      return <div className={"pageBlockDiv "+this.props.className} style={this.constructColor()}>
                <div className="center" style={this.constructWidth()}>
                    {this.props.children}
                </div>
            </div>;
    }
};