import HomePage from './components/pages/HomePage/main';
import NotFoundPage from './components/pages/NotFoundPage';

export default [
  {
    path: '/:id',
    component: HomePage,
  },{
    path: '(.*)',
    component: NotFoundPage,
  },
];
