import React from 'react';
import {
  App,
  View,
} from 'framework7-react';

import routes from '../routes';


  // Framework7 parameters here
  const f7params = {
    id: 'no.iwd.event-checkin', // App bundle ID
    name: 'Ticket app', // App name
    theme: 'md',
    stackPages: true,
    // App routes
    routes,
    view: {
      pushState: true,
      pushStateSeparator:"",
    },
  };

  export default class extends React.Component {

    render() {
      return (
          <App params={f7params}>
            <View id="main-view" url="/" main className="safe-areas"/>
          </App>
      )
    }
  };
